define("ember-intl/helpers/-format-base", ["exports", "@ember/component/helper", "@ember/application", "@ember/utils"], function (_exports, _helper, _application, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); } /**
                                                                                                                                                                                                                                                                                                                                                                                             * Copyright 2015, Yahoo! Inc.
                                                                                                                                                                                                                                                                                                                                                                                             * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
                                                                                                                                                                                                                                                                                                                                                                                             */
  /**
   * @private
   * @hide
   */
  class AbstractHelper extends _helper.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "intl", null);
      _defineProperty(this, "unsubscribeLocaleChanged", null);
      if (this.constructor === AbstractHelper) {
        throw new Error('FormatHelper is an abstract class, can not be instantiated directly.');
      }
      this.intl = (0, _application.getOwner)(this).lookup('service:intl');
      this.unsubscribeLocaleChanged = this.intl.onLocaleChanged(this.recompute, this);
    }
    format() {
      throw new Error('not implemented');
    }
    compute([value], options) {
      if ((0, _utils.isEmpty)(value)) {
        if (options.allowEmpty ?? this.allowEmpty) {
          return;
        }
        if (typeof value === 'undefined') {
          throw new Error(`${this} helper requires value attribute.`);
        }
      }
      return this.format(value, options);
    }
    willDestroy() {
      super.willDestroy();
      this.unsubscribeLocaleChanged();
    }
  }
  _exports.default = AbstractHelper;
});